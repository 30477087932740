import planLevels from '@/modules/plans/data/planLevels'

export default [
  {
    id: 1,
    type: 'light',
    priority_level: planLevels.light,
    description: {
      text: 'Indicado para aquellos que buscan informaciónes sobre insumos',
      highlight: 'informaciónes',
    },
    price: { month: 9.9 },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de variedades de soja y trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulación de fungicidas para soja y trigo',
        included: false,
      },
      {
        id: 5,
        text: 'Comparación de fungicidas',
        included: false,
      },
      {
        id: 6,
        text: 'Eficacias de fungicidas',
        included: false,
      },
      {
        id: 7,
        text: 'Datos ideales para aplicaciones en tiempo real',
        included: false,
      },
      {
        id: 8,
        text: 'Acompañamiento en la productividad de más de 3 sacas por hectárea',
        included: false,
      },
      {
        id: 9,
        text: 'Integracíon con FieldView',
        included: false,
      },
      {
        id: 10,
        text: 'Reportes personalizados',
        included: false,
      },
    ],
    observations: ['Proporcionamos 1 usuario, sin accesos simultáneos'],
  },
  {
    id: 2,
    type: 'pro',
    priority_level: planLevels.pro,
    description: {
      text: 'Indicado para aquellos que deseen reducir costos en la compra de insumos',
      highlight: 'reducir costos',
    },
    price: { month: 299.9 },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de variedades de soja y trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulación de fungicidas para soja y trigo',
        included: true,
      },
      {
        id: 5,
        text: 'Comparación de fungicidas',
        included: true,
      },
      {
        id: 6,
        text: 'Eficacias de fungicidas',
        included: true,
      },
      {
        id: 7,
        text: 'Datos ideales para aplicaciones en tiempo real',
        included: true,
      },
      {
        id: 8,
        text: 'Acompañamiento en la productividad de más de 3 sacas por hectárea',
        included: false,
      },
      {
        id: 9,
        text: 'Integracíon con FieldView',
        included: false,
      },
      {
        id: 10,
        text: 'Reportes personalizados',
        included: false,
      },
    ],
    observations: [
      'Proporcionamos un usuario, con la posibilidad de adquirir nuevas cuentas por un valor de 89,00US$ por usuario por mes',
    ],
  },
  {
    id: 3,
    type: 'full',
    priority_level: planLevels.full,
    description: {
      text: 'Indicado para aquellos que deseen optimizar el manejo de fungicidas',
      highlight: 'manejo de fungicidas',
    },
    price: { month: 209.9, monthlyHectare: 0.2 },
    features: [
      {
        id: 1,
        text: 'Catálogo de fungicidas',
        included: true,
      },
      {
        id: 2,
        text: 'Catálogo de variedades de soja y trigo',
        included: true,
      },
      {
        id: 3,
        text: 'Catálogo de nematicidas',
        included: true,
      },
      {
        id: 4,
        text: 'Simulación de fungicidas para soja y trigo',
        included: true,
      },
      {
        id: 5,
        text: 'Comparación de fungicidas',
        included: true,
      },
      {
        id: 6,
        text: 'Eficacias de fungicidas',
        included: true,
      },
      {
        id: 7,
        text: 'Datos ideales para aplicaciones en tiempo real',
        included: true,
      },
      {
        id: 8,
        text: 'Acompañamiento en la productividad de más de 3 sacas por hectárea',
        included: true,
      },
      {
        id: 9,
        text: 'Integracíon con FieldView',
        included: true,
      },
      {
        id: 10,
        text: 'Reportes personalizados',
        included: true,
      },
    ],
    observations: [
      'Proporcionamos un usuario, con la posibilidad de adquirir nuevas cuentas por un valor de 89,00US$ por usuario por mes',
    ],
  },
]
